
import Vue from "vue";
import { tableColumns } from "./tableColumns";
import { ITableWrapperColumns } from "@/types";
import {
  mortgageMapActions,
  mortgageMapGetters,
  mortgageMapState
} from "@/store/modules/mortgage";

interface ICompanyCompanyData {
  tableColumns: ITableWrapperColumns;
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  companyId: string;
  sortedMortgages: any[];
  mortgageId: string;
  loadingText: string;
}
export default Vue.extend({
  name: "Mortgages",
  components: {
    ImportsUploader: () =>
      import("@/views/admin/imports/ListImports/ImportsUploader.vue")
  },
  data(): ICompanyCompanyData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableColumns: tableColumns,
      loading: false,
      companyId: "",
      sortedMortgages: [],
      mortgageId: "",
      loadingText: ""
    };
  },
  methods: {
    ...mortgageMapActions(["getMortgages", "deleteMortgage"]),
    async fetchPageData(query: any = {}): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Fetching mortgages. Please wait...";
        this.$emit("scrollTop");
        await this.getMortgages({
          query: { ...query, __sort: "mortgageName" }
        });
      } catch (e) {
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    tableActionHandler(action: string) {
      if (action === "Import Mortgages") {
        this.$modal.show("uploadMortgagesImportFileModal");
      }
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "edit":
          if (this.$isCurrentUserAdmin || this.$isCurrentUserUnderwriter) {
            this.$router
              .push(`/admin/mortgages/${data._id}/edit`)
              .catch(() => {});
          } else {
            this.$router.push(`/mortgages/${data._id}/edit`).catch(() => {});
          }
          break;
        case "delete":
          this.deleteCallback(data._id);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneMortgage(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting mortgage. Please wait...";
        await this.deleteMortgage(this.mortgageId);
        this.$modal.hide("mortgagesDeleteModal");
        this.$appNotifySuccess("Mortgage Successfully Deleted");
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    async deleteCallback(id: any): Promise<void> {
      try {
        this.mortgageId = id;
        this.$modal.show("mortgagesDeleteModal");
      } catch (error) {
        if (error !== "cancel") {
          console.error(error.message);
        }
        this.$bugSnagClient.notify(error);
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      if (this.$isCurrentUserAdmin || this.$isCurrentUserUnderwriter) {
        this.$router.push(`/admin/mortgages/${_id}/edit`).catch(() => {});
      } else {
        this.$router.push(`/mortgages/${_id}/edit`).catch(() => {});
      }
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.$router.push(`/admin/mortgages/create`).catch(() => {});
          break;
      }
    }
  },
  computed: {
    ...mortgageMapGetters(["getTotalRecords"]),
    ...mortgageMapState(["mortgages", "links", "makingApiRequest"]),
    tableActions(): string[] {
      return this.$isCurrentUserAdmin ? ["Import Mortgages"] : [];
    },
    tableRowActions(): {
      label: string;
      value: string;
      faIcon: string;
      class?: string;
    }[] {
      let actions: any[] = [{ label: "Edit", value: "edit", faIcon: "edit" }];
      if (this.$isCurrentUserAdmin || this.$isCurrentUserUnderwriter) {
        actions.push({
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        });
      }
      return actions;
    }
  }
});
