import { ImportTypeArr } from "@/store/modules/import/types";
import { format } from "date-fns";
import { capitalize, truncate } from "lodash";

export const tableColumns = {
  mortgageName: {
    formatDataKey: "Mortgage Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  shortName: {
    formatDataKey: "Short Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    simpleFilter: ImportTypeArr.map((type: string) => ({
      label: capitalize(type),
      value: type
    })),
    position: 1
  },
  address: {
    formatDataKey: "Address",
    formatDataValue: (arg: string) => truncate(arg, { length: 22 }),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2
  },
  city: {
    formatDataKey: "City",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    minWidth: "100px"
  },
  state: {
    formatDataKey: "State",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4,
    minWidth: "90px",
    colWidth: 90
  },
  createdOn: {
    formatDataKey: "Created",
    formatDataValue: (arg: string) =>
      arg ? format(new Date(arg), "yyyy-MM-dd") : "N/A",
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 5,
    minWidth: "100px"
  }
};
