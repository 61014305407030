var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonPrimary":_vm.$isCurrentUserAdmin || _vm.$isCurrentUserUnderwriter
      ? {
          text: 'Create New Mortgage',
          key: 'create'
        }
      : {}},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"hideAllFilters":false,"element-loading-text":_vm.loadingText,"data":_vm.mortgages,"tableActions":_vm.tableActions,"addAdvancedFilter":false,"tableColumns":_vm.tableColumns,"hideToggleIcons":true,"totalRecords":_vm.getTotalRecords,"paginationLinks":_vm.links,"tableRowActions":_vm.tableRowActions},on:{"table-row-action":_vm.handleResponseAction,"fetch-query":_vm.fetchPageData,"table-action":_vm.tableActionHandler,"tableRowClicked":_vm.viewCallback}}),_c('ModalBase',{attrs:{"name":"uploadMortgagesImportFileModal","size":"medium","clickToClose":false,"showClose":true,"title":"Upload Import File","loading":_vm.makingApiRequest,"position":"center"}},[_c('ImportsUploader',{attrs:{"defaultType":"mortgages","showHeading":false},on:{"close":function($event){return _vm.$modal.hide('uploadMortgagesImportFileModal')}}})],1),_c('ModalMessage',{attrs:{"name":"mortgagesDeleteModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":"This will delete the mortgage. Continue?","showClose":true,"buttons":[
      {
        text: `Okay`,
        classList: [''],
        click: () => _vm.deleteOneMortgage()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.$modal.hide('mortgagesDeleteModal')
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }